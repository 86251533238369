<template>
  <div class="personal_center_password">
    <div class="password_group">
      <div class="line_group">
        <div class="key">旧密码:</div>
        <input type="password" class="input" placeholder="请输入旧密码" v-model="old" v-show="!show_type" @keyup="inputPwd">
        <input type="text" class="input" placeholder="请输入旧密码" v-model="old" v-show="show_type" @keyup="inputPwd">
        <div class="show_password cp" @click="show_type = !show_type" :class="{show: show_type}"></div>
      </div>
      <div class="line_group">
        <div class="key">输入密码:</div>
        <input type="password" class="input" placeholder="请设置新密码" v-model="pwd" v-show="!show_type" @keyup="inputPwd">
        <input type="text" class="input" placeholder="请设置新密码" v-model="pwd" v-show="show_type" @keyup="inputPwd">
        <!-- <div class="show_password cp" @click="show_type = !show_type" :class="{show: show_type}"></div> -->
      </div>
      <div class="line_group">
        <div class="key">确认密码:</div>
        <input type="password"  class="input" placeholder="请确认新密码" v-model="repwd" v-show="!show_type" @keyup="inputPwd">
        <input type="text"  class="input" placeholder="请确认新密码" v-model="repwd" v-show="show_type" @keyup="inputPwd">
      </div>
      <div class="hint">{{hint}}</div>
      <div class="btn cp un_sel" :class="{on: can_save}" @click="changePwd">保存</div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      old: '',
      pwd:'',
      repwd: '',
      hint:'',
      can_save: false,
      show_type: false
    }
  },
  methods:{
    // 输入密码
    inputPwd () {
      if (this.old == '') {
        this.hint="* 请输入旧密码"
        this.can_save = false
        return
      }
      if (this.pwd != '' && this.repwd != '') {
        if (this.pwd != this.repwd) {
          this.hint="* 两次密码输入不一致"
          this.can_save = false
        }else{
          this.hint=""
          this.can_save = true
        }
      }
    },
    init(){
      this.old = ''
      this.pwd = ''
      this.repwd = ''
      this.hint = ''
      this.can_save = false
    },
    async changePwd () {
      if (this.can_save) {
        let data = {
          loginName: this.$tools.getCookie('user').phone,
          oldpassword: this.old,
          password: this.pwd
        }
        let res = await this.$http.request('/project/updatePassword',data)
        if (res.message === 'success') {
          this.$tools.setCookie(
            'password',
            this.$md5(data.password)
          ) // 存储密码
          this.$store.commit(
            'set_password',
            this.$md5(data.password)
          )
          this.$toast({
            icon:'success',
            title:'',
            desc:'修改成功',
            duration:2000
          })
          this.init()
        } else {
          this.$toast({
            icon:'none',
            title:'修改失败',
            desc:res.describe,
            duration:2000
          })
        }
      }
    }
  },
  mounted () {
    this.init()
  }

}
</script>

<style lang="scss" scoped>
.personal_center_password{
  @include flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #F5F7FA;
  .password_group{
    width: 330px;
  }
  .line_group{
    @include flex;
    width:100%;
    font-size:16px;

    font-weight:400;
    color:rgba(51,51,51,1);
    padding-bottom: 37px;
    position: relative;
    .key{
      @include text_justify;
      @include bbox;
      width: 90px;
      padding-right: 15px;
    }
    .input{
      @include bbox;
      width: 100%;
      flex: 1;
      padding: 17px 20px;
      background-color: transparent;
      border:none;
      border-bottom: 1px solid #ccc;
      outline: none;
      font-size:16px;

      font-weight:400;
      color:rgba(51,51,51,1);
    }
    .show_password{
      @include transition;
      @include background(18px, 12px);
      position: absolute;
      top: 50;
      right: 0;
      z-index: 2;
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/personal_center/hide.png);
      &.show{
         background-image: url(~@/assets/images/personal_center/show.png);
      }
    }
  }
  .hint{
    @include transition;
    height: 23px;
    width: 100%;
    font-size:14px;

    font-weight:400;
    color:rgba(216,46,13,1);
  }
  .btn{
    @include transition;
    text-align: center;
    width: 100%;
    line-height:50px;
    background:rgba(204,204,204,1);
    border-radius:4px;
    font-size:18px;

    font-weight:400;
    color:rgba(255,255,255,1);
    &.on{
      background-color: $main_blue;
    }
  }
}
</style>
